export default {
  translation: {
    REQUEST: "Requesting",
    SAVE: "Save",
    CANCEL: "Cancel",
    NOTES: "Notes",
    ADDFEEDBACK: "Add Feedback",
    UPDATE: "Update",
    DATA_SHARING_PROTOCALL: "Data Sharing Protocol",
    DOWNLOAD_PDF: "Download PDF",
    FILTER: "Filter",
    CASE_DETAILS_ID: "Overview Reference: ",
    CASE_DETAILS: "Overview Reference",
    CASES: "Cases ",
    LOG_OUT: "Log Out",
    CREACT_CASE: "Create Case",
    REQUESTING: "Requesting",
    ADDOUTCOME: "Add Outcome  +",
    ADDOUTCOMEPOPUPHEAD: "Add Outcome",
    EDITOUTCOMEPOPUPHEAD: "Edit Outcome",
    CREATECASESUMMARY: "Create Case",
    EDIT: "Edit",
    CONFIRM: "Confirm",
    DELETE: "Delete",
    ADDENGAGEMENT: "Add Engagement +",
    ADDENGAGEMENTPOPUPHEAD: "Add ",
    EDITENGAGEMENTPOPUPHEAD: "Edit ",
    ADDNOTES: "Add Notes +",
    ADDNOTESPOPUPHEAD: "Add Note",
  },
};
