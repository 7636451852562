import { closeIcon } from "../../../assets/icons";
import { t } from "i18next";
import { useState } from "react";
import createCase from "../../../data/createcase";

// Functional component for Create case
const CreateCasePopup = (props: any) => {
  const [selectedCase, setSelectedCase] = useState<any>(false);
  const [selectedCaseId, setSelectedCaseId] = useState<any>(null);
  const [searchKey, setSearchKey] = useState<string>("");

  // Function to render form iframe
  const _renderFormIframe = () => {
    const iframeUrl = props?.data;
    return (
      <div style={{ width: "100%", height: "80vh" }}>
        <iframe
          src={iframeUrl}
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Form Iframe"
        />
      </div>
    );
  };


  const __renderCases = () => {
    if (searchKey.length < 3) {
      return null
    }

    return createCase.map((element: any, index: number) => {
      if (element.address.toLowerCase().includes(searchKey.toLowerCase()) || element.name.toLowerCase().includes(searchKey.toLowerCase())) {
        return (<div>
          <label className="d-flex pb-3">
            <div className="p-3">
              <input type="radio" id={"checkbox" + index} name={"case"} onChange={(e) => setSelectedCaseId(index)} />
            </div>
            <div className="flex-grow-1">

              <p className="m-0"><b>{element.name}</b></p>
              <p>{element.address}</p>

            </div>
          </label>
        </div>)
      }
    });
  }

  return (
    <div className="addoutcome-popup">
      <div
        className="modal fade show"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("CREATECASESUMMARY")}
              </h5>
              <button
                className="btn btn-transparent close"
                onClick={() => props.onSubmit("", null)}
              >
                <img src={closeIcon} alt="close" />
              </button>
            </div>
            <div className="modal-body">
              {
                !selectedCase ?
                  <div>
                    <div className="pb-3">
                      <input type="text" className="form-control p-3" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
                    </div>
                    {__renderCases()}

                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <button className="btn btn-primary" disabled={selectedCaseId !== null ? false : true} onClick={() => props.onSubmit("", null)}>Select Case</button>
                      </div>
                      <div>
                        <button className="btn btn-primary" onClick={() => props.onSubmit("", null)}>Crease Case</button>
                      </div>
                    </div>
                  </div> :
                  <div className="content-container">{_renderFormIframe()}</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCasePopup;
