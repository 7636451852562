// Import required modules and configuration
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

// Import the action types from the constants file
import * as types from "./types";
import { getNewAxiosInstance } from "../../../utils";


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseSummaryData(res: any): SagaIterator {
    const dataId = res.payload.dataId

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/casesummarydata/${res.payload.dataId}/${res.payload.caseId}`;
        if (res.payload.fetchSelected) {
            apiEndPoint = `/casesummarydata/${res.payload.caseId}?dataIds=${res.payload.dataId}`;
        }

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.CASE_SUMMARY_DATA_RECEIVE, 
            payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_SUMMARY_DATA_FAILURE, payload: { dataId: dataId, error: e } });
    }
}


/**
 * Fetches overview reference from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchOverviewReference(res: any): SagaIterator {

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch overview reference
        const response: AxiosResponse = yield call(ins.get, `${res.payload.url}?appId=${res.payload.appId}&externalSystemReference=${res.payload.caseReference}&dspRoleId=${res.payload.dspRoleId}`);

        // Dispatch action to update state with received data
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches overview reference access from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchOverviewReferenceAccess(res: any): SagaIterator {

    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch overview reference
        const response: AxiosResponse = yield call(ins.get, `/oneviewreference/oneViewAccess?dspId=${res.payload.dspId}&dspRoleId=${res.payload.dspRoleId}&oneViewReference=${res.payload.oneViewReference}`);

        // Dispatch action to update state with received data
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_ACCESS_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FETCH_OVERVIEWREFERENCE_ACCESS_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches case summary config from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchCaseSummaryConfigAPI(res: any): SagaIterator {
    try {

        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/casesummaryconfig/config/${res.payload.caseSummaryType}`);

        // Dispatch action to update state with received data
        yield put({ type: types.CASE_SUMMARY_CONFIG_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_SUMMARY_CONFIG_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches information governance data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchInformationGovernanceAPI(res: any): SagaIterator {
    try {

        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch information governance data
        const response: AxiosResponse = yield call(ins.get, `/ig/IGConfig`, {params: res.payload.params});

        // Dispatch action to update state with received data
        yield put({ type: types.INFORMATION_GOVERNANCE_DATA_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.INFORMATION_GOVERNANCE_DATA_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches IGAPI data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */

function* fetchIGApi(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch information governance data
        const apiCall = () => {
            return ins.post(`${res.payload.url}?dspId=${res.payload.body}`, null, { headers: { accept: 'application/json', 'caseSummaryType': res.payload.caseSummaryType } })
        }

        const response: AxiosResponse = yield call(apiCall);

        // Dispatch action to update state with received data
        yield put({ type: types.IGAPI_DATA_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.IGAPI_DATA_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches Feedback-cs-columns data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSColumnAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/cs-feedback/columns?caseSummaryType=${res.payload.params.caseSummaryType}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_COLUMNS_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_COLUMNS_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.get, `/cs-feedback?caseSummaryType=${res.payload.params.caseSummaryType}&oneViewReference=${res.payload.params.oneViewReference}&dspId=${res.payload.params.dspId}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs-add data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSAddAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);



        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.post, `/cs-feedback/add`, res.payload.params);

        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_ADD_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_ADD_FAILURE, payload: { error: e.response } });
    }
}

/**
 * Fetches Feedback-cs-edit data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSEditAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.put, `/cs-feedback/edit`, res.payload.params);

        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_EDIT_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_EDIT_FAILURE, payload: { error: e.response } });
    }
}


/**
 * Fetches Feedback-cs-delete data from the API and updates the state.
 *
 * @param {any} res - The response object containing payload.
 */
function* fetchFeedbackCSDeleteAPI(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        // Perform GET request to fetch case summary config
        const response: AxiosResponse = yield call(ins.delete, `/cs-feedback/delete?oneViewReference=${res.payload.params.oneViewReference}&feedbackId=${res.payload.params.feedbackId}`);
        // Dispatch action to update state with received data
        yield put({ type: types.FEEDBACK_CS_DELETE_RECEIVE, payload: response.data });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.FEEDBACK_CS_DELETE_FAILURE, payload: { error: e.response } });
    }
}


/**
 * The main saga watcher. Will fork all other sagas into the middleware.
 */
export default function* fetchSummaryOneViewSaga() {
    yield takeLatest (types.CASE_SUMMARY_CONFIG_REQUEST, fetchCaseSummaryConfigAPI);
    yield takeLatest (types.INFORMATION_GOVERNANCE_DATA_REQUEST, fetchInformationGovernanceAPI);
    yield takeLatest (types.FETCH_OVERVIEWREFERENCE_REQUEST, fetchOverviewReference);
    yield takeLatest (types.FETCH_OVERVIEWREFERENCE_ACCESS_REQUEST, fetchOverviewReferenceAccess);
    yield takeLatest (types.IGAPI_DATA_REQUEST, fetchIGApi);
    yield takeEvery  (types.CASE_SUMMARY_DATA_REQUEST, fetchCaseSummaryData);
    

    yield takeEvery(types.FEEDBACK_CS_DELETE_REQUEST, fetchFeedbackCSDeleteAPI);
    yield takeEvery(types.FEEDBACK_CS_EDIT_REQUEST, fetchFeedbackCSEditAPI);
    yield takeEvery(types.FEEDBACK_CS_COLUMNS_REQUEST, fetchFeedbackCSColumnAPI);
    yield takeLatest(types.FEEDBACK_CS_ADD_REQUEST, fetchFeedbackCSAddAPI);
    yield takeLatest(types.FEEDBACK_CS_REQUEST, fetchFeedbackCSAPI);
}