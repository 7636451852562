// Import dependencies
import { useEffect, useRef, useState } from "react";
// import components and configuration
import {
  TopHeader,
  GridLoader,
  AsideFilterLoader,
  CaseSummaryLoader,
  Tabs,
} from "./component";
import {
  AuthWrapperCM as AuthWrapper,
  DropDown,
  TextField,
  TwoColumnLayout,
  Button,
  FeedbackPopup,
} from "../../components";
import KendoGrid from "../../components/containers/kendo-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecordById,
  editCaseRecordById,
  fetchCaseConfigByDSPId,
  fetchChildGridDataById,
  fetchCohortsDataByFilter,
  fetchCreateCase,
  fetchDSPId,
  fetchGridDataById,
  fetchPicklistById,
  resetCaseConfigByDSPId,
  resetCreateCase,
  resetDeleteRecordById,
  resetEditCaseRecordById,
  resetFetchChildGridDataById,
  resetFetchCohortsDataByFilter,
  resetFetchDSPId,
  resetFetchGridDataById,
  resetFetchPicklistById,
} from "./store/actions";
import {
  GROUP_REF_SELETECTED,
  TOKEN,
  getData,
  setData,
} from "../../utils/Storage";
import { toast } from "react-toastify";

import CaseSummaryCM from "../case-summary";
import { Layout } from "../case-summary/components";
import { useTranslation } from "react-i18next";
import { closeIcon, map_white, notification } from "../../assets/icons";
import dashboardService from "./services/dashboard-service";
import { REDIRECT_DOMAIN_NAME } from "../../config/ApiConstants";
import { infinityLoader } from "../../config/Images";
import { Link, useNavigate } from "react-router-dom";
import OutcomesGrid from "../case-summary/components/OutcomesGrid";
import EngagmentTrackerGrid from "../case-summary/components/EngagmentTrackerGrid";
import CasedetailsGrid from "../case-summary/components/CasedetailsGrid";
import {
  fetchFeedbackCSRequest,
  resetFeedbackCS,
  resetFetchHHC,
} from "../case-summary/store/actions";

import CustomAction from "./component/customAction";
import EditCasePopup from "./component/editCaseDataPopup";
import CommunityAssets from "../community-assets";

import { map, graph } from "../../assets/icons";
import CreateCasePopup from "../case-summary/components/CreateCasePopup";

// Interface to type-check pagination states
interface PageState {
  skip: number;
  take: number;
}
// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 25 };


const Dashboard = (props: any) => {
  const iframeEle = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exportToExcel: any = new CustomEvent("exportData", {
    detail: { export: true },
  });
  const expandColumns: any = new CustomEvent("expandColumns", {
    detail: { export: true },
  });

  const editCaseDataState = useSelector((state: any) => state.editCaseDetails);
  const dspCodes = useSelector((state: any) => state.dspCodes);
  const caseManagementConfig = useSelector(
    (state: any) => state.caseManagementConfig
  );
  const getPickerListById = useSelector(
    (state: any) => state.getPickerListById
  );
  const mainGridData = useSelector((state: any) => state.mainGridData);
  const cohortsGridData = useSelector((state: any) => state.cohortsGridData);
  const childGridData = useSelector((state: any) => state.childGridData);
  const caseSummaryConfig: any = useSelector<any>((state) => state.caseSummaryConfigCM);
  const deleteRecordByIdData: any = useSelector<any>((state) => state.deleteRecordById);
  const hhcalertdata: any = useSelector<any>((state) => state.HHCAlert);
  const feedbackCslistdata: any = useSelector<any>((state) => state.feedbackCs);
  const overviewreferenceaccess: any = useSelector<any>((state) => state.overviewreferenceCM);

  const editCaseDataRed = useSelector((state: any) => state.editCaseDetails);

  const caseData: any = useSelector<any>((state) => state.caseDataCM);
  const caseDetailAlldata = useSelector((state: any) => state.caseDetailAllCM);
  const createCaseData = useSelector((state: any) => state.createCaseData);

  const [caseSummaryType, setCaseSummaryType] = useState<any>(null);
  const [mainGridDataLoading, setMainGridDataLoading] = useState<any>(true);
  const [filterType, setFilterType] = useState(1); // 1. Main Data, 2. Cohorts
  const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
  const [leftSidePanel, setLeftSidePanel] = useState<any>(null);
  const [mainSection, setMainSection] = useState<any>(null);
  const [selectedDSP, setSelectedDSP] = useState<any>(null);
  const [filterList, setFilterList] = useState<any>([]);
  const [showFeedbackpopup, setShowFeedbackpopup] = useState<boolean>(false);
  const [gridDataRows, setGridDataRows] = useState<any>([]);
  const [gridData, setGridData] = useState<any>(null);
  const [isCommunityAssets, setIsCommunityAssets] = useState<boolean>(false);
  const [isCommunityAssetsEnabled, setIsCommunityAssetsEnabled] = useState<boolean>(true);
  const [createCaseStateData, setCreateCaseStateData] = useState<any>(null);
  const [createCaseshowModel, setCreateCaseshowModel] =
    useState<Boolean>(false);

  const [childGridsData, setChildGridsData] = useState<any>(null);
  const [gridPageCount, setGridPageCount] = useState<any>(10);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [gridId, setGridId] = useState<any>(null);
  const [feedbackList, setFeedbackList] = useState<any>(null);
  const [selectedCohortId, setSelectedCohortId] = useState<any>(null);
  const [cohortSearchString, setCohortSearchString] = useState<any>("");
  const [isShowFeedback, setIsShowFeedback] = useState<boolean>(false);
  const [isCaseDetailedMode, setIsCaseDetailedMode] = useState<boolean>(false);
  const [selectedCase, setSelectedCase] = useState<any>(null);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>(null);

  const [hiddenCountColumns, setHiddenCountColumns] = useState<any>();
  const [isHiddenColumns, setIsHiddenColumns] = useState<any>(false);

  const [editCasePopup, setEditCasePopup] = useState<any>(false);
  const [editCaseData, setEditCaseData] = useState<any>(null);

  const [isUsername, setIsUsername] = useState<any>("");
  const [demographicsData, setDemographicsData] = useState<any>(null);
  const [caseDataState, setCaseDataState] = useState<any>(null);

  const [totalDataCount, setTotalDataCount] = useState<number | null>(null);
  const [totalPagesCount, setTotalPagesCount] = useState<number | null>(null);

  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number | null>(1);

  const [dataState, setDataState] = useState<any>(initialDataState);
  const [HHCMessage, setHHCMessage] = useState<any>(null);

  const [reportLinks, setReportLinks] = useState<any>(null);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [isReporting, setIsReporting] = useState<boolean>(false);

  // constructor(props: any) {
  useEffect(() => {
    window.addEventListener("communityAssets", (event) => {
      setIsCommunityAssets(true);
    });

    try {
      setSelectedCaseReference(null);
      setSelectedCase(null);
      setIsCaseDetailedMode(false);
      getData(TOKEN).then((res: any) => {
        let token;
        if (res?.provider === "msal") {
          token = { isLegacy: false, token: res.token };
        } else {
          token = { isLegacy: true, token: res.token };
        }

        setToken(token);
        // fetch dsp id
        if (selectedDSP === null) {
          dispatch(fetchDSPId({ token: token }));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (
      overviewreferenceaccess.isSuccess &&
      overviewreferenceaccess.data !== null
    ) {
      let data = overviewreferenceaccess.data;

      if (data.accessCaseSummaryFlag === 1) {
        setCaseSummaryType(data.caseSummaryTypeId);
      }
    }
  }, [overviewreferenceaccess]);

  useEffect(() => {
    // Process received Information Create case data
    try {
      if (createCaseData.isSuccess && createCaseData.data !== null) {
        setCreateCaseStateData(createCaseData.data);
        setCreateCaseshowModel(true);

        dispatch(resetCreateCase());
      } else if (createCaseData.isError) {
        toast.error(createCaseData?.data?.error?.data);
        dispatch(resetCreateCase());
        setCreateCaseshowModel(false);
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [createCaseData]);

  useEffect(() => {
    if (editCaseDataRed.isSuccess && editCaseDataRed.data !== null) {
      if (filterType === 1) {
        dispatch(
          fetchGridDataById({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              filtersJson: __processFilterData(selectedFilters),
              // pageNumber: currentPage,
              // pageSize: recordsPerPage
            },
          })
        );
      } else if (filterType === 2) {
        // dispatch(
        //   fetchCohortsDataByFilter({
        //     token: token,
        //     param: {
        //       gridId: gridId,
        //       dspId: selectedDSP,
        //       cohortId: selectedCohortId,
        //       searchString: cohortSearchString,
        //       pageNumber: currentPage,
        //       pageSize: recordsPerPage
        //     },
        //   })
        // );
      }
    }
  }, [editCaseData]);

  useEffect(() => {
    try {
      if (dspCodes.isSuccess && dspCodes.data !== null) {
        setSelectedDSP(dspCodes.data.defaultSelectedValue);

        __fetchSelectedDSP(dspCodes.data.defaultSelectedValue);

        setSelectedCase(null);
        setSelectedCaseReference(null);
        setIsCaseDetailedMode(false);
        dispatch(resetFetchDSPId());
      } else if (dspCodes.isError) {
        dispatch(resetFetchDSPId());
      }
    } catch (e) {
      console.log(e);
    }
  }, [dspCodes]);

  useEffect(() => {
    try {
      if (
        deleteRecordByIdData.isSuccess &&
        deleteRecordByIdData.data !== null
      ) {
        toast.success("Record deleted successfully");

        if (filterType === 1) {
          dispatch(
            fetchGridDataById({
              token: token,
              param: {
                gridId: gridId,
                dspId: selectedDSP,
                filtersJson: __processFilterData(selectedFilters),
                // pageNumber: currentPage,
                // pageSize: recordsPerPage
              },
            })
          );
        } else if (filterType === 2) {
          // dispatch(
          //   fetchCohortsDataByFilter({
          //     token: token,
          //     param: {
          //       gridId: gridId,
          //       dspId: selectedDSP,
          //       cohortId: selectedCohortId,
          //       searchString: cohortSearchString,
          //       pageNumber: currentPage,
          //       pageSize: recordsPerPage
          //     },
          //   })
          // );
        }
        dispatch(resetDeleteRecordById());
      } else if (deleteRecordByIdData.isError) {
        console.log(deleteRecordByIdData.data);
        toast.error("Something went wrong, please try again later");
        dispatch(resetDeleteRecordById());
      }
    } catch (e) {
      console.log(e);
    }
  }, [dspCodes]);

  useEffect(() => {
    if (caseManagementConfig.isSuccess && caseManagementConfig.data !== null) {
      setIsCommunityAssetsEnabled(caseManagementConfig.data.isCaIconVisible)
      const data = caseManagementConfig.data.panels;
      if (data[0].type === "Report") {
        setIsReporting(true);
        if (data && data.length > 0) {
          data.forEach((item: any) => {
            switch (item.panelId) {
              case 3:
                const flatList =
                  item.primarySections[0].form.controls[0].treeItems.items;
                const defaultSelected =
                  item.primarySections[0].form.controls[0].treeItems
                    .defaultSelectedValue;

                // Convert flat list to a lookup by parentId
                const buildLookup = (
                  items: any[]
                ): Map<string | null, any[]> => {
                  const lookup = new Map<string | null, any[]>();
                  items.forEach((item) => {
                    if (item.id === defaultSelected) {
                      setSelectedReport(item);
                    }
                    const children = lookup.get(item.parentId) || [];
                    children.push(item);
                    lookup.set(item.parentId, children);
                  });
                  return lookup;
                };

                setReportLinks(buildLookup(flatList));
                setLeftSidePanel(item);
                break;

              default:
                return null;
            }
          });
        }
      } else {
        setIsReporting(false);
        setIsUsername(caseManagementConfig.data.username);
        if (data && data.length > 0) {
          data.forEach((item: any) => {
            switch (item.panelId) {
              case 1:
                setLeftSidePanel(item);
                break;

              case 2:
                setMainSection(item);
                break;

              default:
                return null;
            }

            item.primarySections.forEach((section: any) => {
              section?.form?.controls.forEach((field: any) => {
                switch (field.type) {
                  case "selectpicker":
                    const params: any = {};
                    field.apiPicklistEndpoint.parameters.forEach(
                      (item: any) => {
                        params["controlId"] = field.controlId;
                        if (item === "dspId") {
                          params["dspId"] = selectedDSP;
                        } else {
                          params[item] = field[`${item}`];
                        }
                      }
                    );

                    dispatch(
                      fetchPicklistById({
                        token: token,
                        param: params,
                        endpoint: field.apiPicklistEndpoint.endpointUrl,
                      })
                    );
                    break;

                  default:
                    break;
                }

                const tempArray = [...filterList];
                const index = tempArray.findIndex((item: any) => {
                  return item.controlId === field.controlId;
                });

                if (index > -1) {
                  tempArray[index].values = [];
                } else {
                  setFilterList((prevState: any) => {
                    return [
                      ...prevState,
                      { ...field, values: [], panelID: item.panelId },
                    ];
                  });
                }
              });

              if (section.type === "datagrid") {
                setGridId(section.dataGrid.dataGridId);
                dispatch(
                  fetchGridDataById({
                    token: token,
                    param: {
                      gridId: section.dataGrid.dataGridId,
                      dspId: selectedDSP,
                      filtersJson: [],
                      // pageNumber: currentPage,
                      // pageSize: recordsPerPage
                    },
                  })
                );
              }
            });
          });
        }
      }

      dispatch(resetCaseConfigByDSPId());
    } else if (caseManagementConfig.isError) {
      if (
        caseManagementConfig.data?.error?.response?.status === 404 &&
        REDIRECT_DOMAIN_NAME !== undefined
      ) {
        // if case management config not found redirect to case management domain
        window.location.replace(REDIRECT_DOMAIN_NAME);
      } else {
        toast.error("Something went wrong, please try again later");
      }

      dispatch(resetCaseConfigByDSPId());
    }
  }, [caseManagementConfig]);

  useEffect(() => {
    if (editCaseDataState.isSuccess && editCaseDataState.data !== null) {
      toast.success("Case Details updated successfully");

      if (filterType === 1) {
        dispatch(
          fetchGridDataById({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              filtersJson: __processFilterData(selectedFilters),
              // pageNumber: currentPage,
              // pageSize: recordsPerPage
            },
          })
        );
      } else if (filterType === 2) {
        // dispatch(
        //   fetchCohortsDataByFilter({
        //     token: token,
        //     param: {
        //       gridId: gridId,
        //       dspId: selectedDSP,
        //       cohortId: selectedCohortId,
        //       searchString: cohortSearchString,
        //       pageNumber: currentPage,
        //       pageSize: recordsPerPage
        //     },
        //   })
        // );
      }
      dispatch(resetEditCaseRecordById());
    } else if (editCaseDataState.isError) {
      toast.error(editCaseDataState.data.error.message);
      dispatch(resetEditCaseRecordById());
    }
  }, [editCaseDataState]);
  useEffect(() => {
    if (getPickerListById.isSuccess && getPickerListById.data !== null) {
      const data = getPickerListById.data;

      const newFilters = [...filterList];
      const index = newFilters.findIndex((item: any) => {
        return data.controlId === item.controlId;
      });

      if (index > -1) {
        newFilters[index].values = data.values;
        newFilters[index].defaultSelectedValue = data?.defaultSelectedValue;
        newFilters[index].selectedItem = null;
      }

      setFilterList(newFilters);

      dispatch(resetFetchPicklistById());
    } else if (getPickerListById.isError) {
      toast.error("Something went wrong, please try again later");
      dispatch(resetFetchPicklistById());
    }
  }, [getPickerListById]);

  useEffect(() => {
    if (mainGridData.isSuccess === true && mainGridData.data !== null) {
      setMainGridDataLoading(false);
      const masterData = mainGridData.data;
      const paging = masterData.paging;

      const data = dashboardService.processDataForKendoGrid(masterData);

      // setDataState({
      //   skip: (paging.currentPage * recordsPerPage) - recordsPerPage,
      //   take: recordsPerPage
      // })

      setGridData(data);
      setGridDataRows({ data: data.gridRows, total: paging.totalCount });

      setTotalDataCount(paging.totalCount);
      setTotalPagesCount(paging.totalPages);

      dispatch(resetFetchGridDataById());
    } else if (mainGridData.isError) {
      toast.error("Something went wrong, please try again later");
      dispatch(resetFetchGridDataById());
    }
  }, [mainGridData]);

  useEffect(() => {
    if (childGridData.isSuccess === true && childGridData.data !== null) {
      const childData = dashboardService.processDataForKendoGridChildData(
        childGridData.data
      );
      const indexOfInGridRowData = gridDataRows.data.findIndex(
        (item: any) => item.Group_Ref === childGridData.data.oneViewReference
      );
      if (indexOfInGridRowData !== -1) {
        let tempArray = [...gridDataRows.data];
        tempArray[indexOfInGridRowData].details = childData;
        setGridDataRows({ data: tempArray, total: gridDataRows.total });
      }
      dispatch(resetFetchChildGridDataById());
    } else if (childGridData.isError) {
      toast.error("Something went wrong, please try again later");
      dispatch(resetFetchChildGridDataById());
    }
  }, [childGridData]);

  useEffect(() => {
    // Process received case data
    try {
      if (caseData.data && caseData.data !== null) {
        setCaseDataState(caseData.data);
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [caseData]);

  useEffect(() => {
    // Process received case summary config data
    try {
      if (caseSummaryConfig.isSuccess && caseSummaryConfig.data !== null) {
        const data = caseSummaryConfig.data.panels;
        setIsShowFeedback(caseSummaryConfig.data.isAddFeedBackButtonVisible);
        if (data && data.length > 0) {
          data.forEach((item: any) => {
            switch (item.title) {
              case "Demographics":
                setDemographicsData(item);
                break;

              default:
                return "none";
            }
          });
        }
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [caseSummaryConfig]);

  useEffect(() => {
    if (cohortsGridData.isSuccess === true && cohortsGridData.data !== null) {
      const masterData = cohortsGridData.data;
      const paging = masterData.paging;
      const data = dashboardService.processDataForKendoGrid(masterData);

      setDataState({
        skip: paging.currentPage * recordsPerPage - recordsPerPage,
        take: recordsPerPage,
      });

      setGridData(data);
      setGridDataRows({ data: data.gridRows, total: paging.totalCount });

      setTotalDataCount(paging.totalCount);
      setTotalPagesCount(paging.totalPages);

      dispatch(resetFetchCohortsDataByFilter());
    } else if (cohortsGridData.isError) {
      toast.error("Something went wrong, please try again later");
      dispatch(resetFetchCohortsDataByFilter());
    }
  }, [cohortsGridData]);

  const feedbackCsApiCall = (token: any, oneViewReference_res: any) => {
    let body = {
      token: token,
      caseSummaryType: caseSummaryType,
      dspId: selectedDSP,
      oneViewReference: oneViewReference_res,
    };

    dispatch(fetchFeedbackCSRequest({ token: token, params: body }));
  };

  useEffect(() => {
    if (feedbackCslistdata.isSuccess && feedbackCslistdata.data !== null) {
      setFeedbackList(feedbackCslistdata.data.gridRows.length);
      dispatch(resetFeedbackCS());
    } else if (feedbackCslistdata.isError) {
      toast.error(
        feedbackCslistdata.data.error.data ||
          feedbackCslistdata.data.error.status
      );
      dispatch(resetFeedbackCS());
    }
  }, [feedbackCslistdata]);

  useEffect(() => {
    if (hhcalertdata.isSuccess === true && hhcalertdata.data !== null) {
      setHHCMessage(hhcalertdata.data);
      dispatch(resetFetchHHC());
    } else if (hhcalertdata.isError) {
      dispatch(resetFetchHHC());
    }
  }, [hhcalertdata]);

  const __fetchSelectedDSP = (selectedDSP: any) => {
    try {
      dispatch(fetchCaseConfigByDSPId({ dspId: selectedDSP, token: token }));
    } catch (e) {
      toast.error("Something went wrong, please try again later");
    }
  };

  const __callCreateCaseApi = () => {
    try {
      dispatch(fetchCreateCase({ token: token }));
    } catch (e) {
      toast.error("Something went wrong, please try again later");
    }
  };

  const __setFilterValue = (e: any, controlId: number) => {
    let value: any = "";

    if (e.value !== undefined) {
      value = e.value;
    } else {
      if (e.length > 0) {
        value = e.map((val: any) => val.value).join(", ");
      }
    }

    setSelectedFilters((prevState: any) => ({
      ...prevState,
      [controlId]: {
        controlId: controlId,
        Values: value,
      },
    }));
  };

  const __onSubmitFilter = (e: any) => {
    e.preventDefault();
    setSelectedCaseReference(null);
    setSelectedCase(null);
    setIsCaseDetailedMode(false);
    setFilterType(1);
    try {
      if (mainGridData.isFetching || cohortsGridData.isFetching) {
        toast.error("Please wait, data is loading");
      } else {
        setCurrentPage(1);

        dispatch(
          fetchGridDataById({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              filtersJson: __processFilterData(selectedFilters),
              // pageNumber: 1,
              // pageSize: recordsPerPage
            },
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const __processFilterData = (data: any) => {
    let tempArray = [];
    for (const item in data) {
      tempArray.push(data[item]);
    }
    return tempArray;
  };

  const __clearFilterData = () => {
    setSelectedFilters([]);
    const temp = [...filterList];
    temp.forEach((item: any) => {
      if (item.controlId !== 20) {
        console.log("Control ID", item);
        item.defaultSelectedValue = "";
        item.selectedItem = null;
      }
    });
    setFilterList(temp);

    if (mainGridData.isFetching || cohortsGridData.isFetching) {
      toast.error("Please wait, data is loading");
    } else {
      setCurrentPage(1);

      dispatch(
        fetchGridDataById({
          token: token,
          param: {
            gridId: gridId,
            dspId: selectedDSP,
            filtersJson: __processFilterData([]),
            // pageNumber: 1,
            // pageSize: recordsPerPage
          },
        })
      );
    }
  };

  // const __onPageChange = (res: any) => {
  //   setRecordsPerPage(res.page.take)

  //   let page = (res.page.skip / res.page.take) + 1

  //   setCurrentPage(page)

  //   if (filterType === 1) {
  //     dispatch(
  //       fetchGridDataById({
  //         token: token,
  //         param: {
  //           gridId: gridId,
  //           dspId: selectedDSP,
  //           filtersJson: __processFilterData(selectedFilters),
  //           // pageNumber: page,
  //           // pageSize: res.page.take
  //         },
  //       })
  //     );
  //   } else if (filterType === 2) {
  //     // dispatch(
  //     //   fetchCohortsDataByFilter({
  //     //     token: token,
  //     //     param: {
  //     //       gridId: gridId,
  //     //       dspId: selectedDSP,
  //     //       cohortId: selectedCohortId,
  //     //       searchString: cohortSearchString,
  //     //       pageNumber: page,
  //     //       pageSize: res.page.take
  //     //     },
  //     //   })
  //     // );
  //   }
  // }

  // Render aside layout
  const __renderCaseSummaryAside = () => {
    if (caseSummaryConfig.isFetching) {
      return (
        <div style={{ textAlign: "center" }}>
          <img src={infinityLoader} alt="loading" style={{ width: 40 }} />
        </div>
      );
    }

    return (
      <>
        <Layout
          data={demographicsData}
          caseData={caseDataState}
          isAside={true}
          onClickExpand={(id: string) => {
            console.log(id);
          }}
          onClickFetchCaseData={(dataId: string) => {
            // __fetchCaseDataAPI(dataId, onveViewReferenceId);
          }}
        />

        {/* {
          hhcalertdata.isSuccess && HHCMessage !== null ?
            <div style={{ position: "absolute", bottom: 10, left: 10 }}>
              <button className="btn" onClick={() => {
                toast.success(HHCMessage)
                setTimeout(() => {
                  setHHCMessage(null)
                }, 1000)
              }} style={{ border: "2px solid #5464B0", borderRadius: 19, width: 38, height: 38, padding: 0, background: "#ffffff" }}>
                <img src={notification} alt="map" style={{ width: 28 }} />
              </button>
            </div> : null
        } */}
      </>
    );
  };

  // function to render the aside filter
  const _renderFilterAside = () => {
    if (selectedCaseReference !== null) {
      return __renderCaseSummaryAside();
    }

    let __renderFields: any = [];
    let __renderButtons: any = [];

    filterList?.forEach((field: any, index: number) => {
      if (field.panelID === 1) {
        switch (field.type) {
          case "selectpicker":
            __renderFields.push(
              <DropDown
                label={field.label}
                labelExternal={true}
                key={"filters" + index}
                name={field.name}
                data={field.values}
                defaultSelected={
                  field?.defaultSelectedValue !== undefined
                    ? field.defaultSelectedValue
                    : null
                }
                isMultiSelectPickList={field.isMultiSelectPickList}
                onChange={(e: any) => __setFilterValue(e, field.controlId)}
              />
            );

            break;
          case "text":
            __renderFields.push(
              <TextField
                key={"text_field" + index}
                label={field.label}
                labelExternal={true}
                name={field.name}
                placeholder={field.placeholder}
                defaultSelected={""}
                onChange={(e: any) => __setFilterValue(e, field.controlId)}
              />
            );

            break;
          case "submit":
          case "reset":
            __renderButtons.push(
              <div>
                <Button
                  key={index}
                  title={field.label}
                  className={
                    field.type === "submit"
                      ? "btn-cta blue-background"
                      : "btn-cta transparent-background"
                  }
                  type={field.type}
                  onClick={() =>
                    field.type === "reset" ? __clearFilterData() : null
                  }
                  disabled={
                    cohortsGridData.isFetching || mainGridData.isFetching
                  }
                />
              </div>
            );

            break;
          default:
            return null;
        }
      }
    });

    return (
      <div className="aside-filter-content" key={11}>
        {caseManagementConfig.isFetching ? (
          <AsideFilterLoader />
        ) : (
          <form method="get" onSubmit={(e) => __onSubmitFilter(e)}>
            <div className="filter-list-container">{__renderFields}</div>

            {/* action buttons for the aside fields  */}
            <div className="button-container">{__renderButtons}</div>
          </form>
        )}
      </div>
    );
  };

  const __onSubmitCohorts = (e: any) => {
    e.preventDefault();
    setSelectedCaseReference(null);
    setSelectedCase(null);
    setIsCaseDetailedMode(false);
    setFilterType(2);
    try {
      if (mainGridData.isFetching || cohortsGridData.isFetching) {
        toast.error("Please wait, data is loading");
      } else {
        if (selectedCohortId !== null) {
          dispatch(
            fetchCohortsDataByFilter({
              token: token,
              param: {
                gridId: gridId,
                dspId: selectedDSP,
                cohortId: selectedCohortId,
                searchString: cohortSearchString,
                // pageNumber: currentPage,
                // pageSize: recordsPerPage
              },
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const __fetchChildData = (e: any) => {
    try {
      if (e?.details === undefined) {
        dispatch(
          fetchChildGridDataById({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              oneViewReference: e.Group_Ref,
            },
          })
        );
      } else {
        const indexOfInGridRowData = gridDataRows.data.findIndex(
          (item: any) => item.Group_Ref === e.Group_Ref
        );
        if (indexOfInGridRowData !== -1) {
          let tempArray = [...gridDataRows.data];
          tempArray[indexOfInGridRowData].details = undefined;
          setGridDataRows({ data: tempArray, total: gridDataRows.total });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const __onCustomAction = (res: any) => {
    try {
      if (res.action === "details") {
        setData(GROUP_REF_SELETECTED, res.data.Group_Ref);
        setIsCaseDetailedMode(true);
        setSelectedCase(res.data);
        setSelectedCaseReference(res.data.Group_Ref);
        setDemographicsData(null);
      } else if (res.action === "delete") {
        // const confirm = window.confirm("Are you sure you want to delete this record?");
        // confirm ?
        dispatch(
          deleteRecordById({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              oneViewReference: res.data.Group_Ref,
            },
          })
        );
        // : null
      } else if (res.action === "edit") {
        setEditCasePopup(true);
        setEditCaseData(res.data);
        // const indexOfInGridRowData = gridDataRows.data.findIndex((item: any) => item.Group_Ref === e.dataItem.Group_Ref);
        // let tempArray = [...gridDataRows.data]
        // tempArray[indexOfInGridRowData].inEdit = true;

        // setGridDataRows({ data: tempArray, total: gridDataRows.total });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "map":
        return map;

      case "graph":
        return graph;

      default:
        return "";
    }
  };

  const __onReportClick = (item: any) => {
    if (
      item.parentId === 0 &&
      (item.reportLink === null ||
        item.reportLink === undefined ||
        item.reportLink === "")
    ) {
      return;
    }

    if (item.isEnabled) {
      setSelectedReport(item);
    } else {
      toast.error(item.disableClickMessage);
    }
  };

  const __printReport = () => {
    console.log("Print Report", selectedReport.reportLink);
    window.open(selectedReport.reportLink, "_blank");
    // iframeEle?.current.contentWindow.print();
  };

  const __renderList = (parentId: number, lookup: any) => {
    if (reportLinks === null) {
      return "";
    }

    const children = reportLinks.get(parentId);
    if (!children || children.length === 0) {
      return "";
    }

    let html = children.map((item: any, key: number) => {
      return (
        <li key={key} className={item.parentId === 0 ? "parent" : "child"}>
          <div className={item?.iconName ? "d-flex" : ""}>
            {item?.iconName ? (
              <div className="icon">
                <img
                  src={getIcon(item.iconName)}
                  alt={item.iconName}
                  width={30}
                />
              </div>
            ) : null}
            <div className="link">
              <button
                className={
                  "btn btn-link " +
                  (selectedReport?.id === item.id ? "active" : "") +
                  " " +
                  (item.parentId === 0
                    ? "parent"
                    : "child" +
                      " " +
                      (item.isEnabled === false ? "silveraccess" : ""))
                }
                onClick={() => __onReportClick(item)}
                disabled={
                  !item.isEnabled
                    ? false
                    : (item.reportLink === null ||
                        item.reportLink === undefined ||
                        item.reportLink === "") &&
                      item.parentId !== 0
                }
              >
                <span className={item.parentId === 0 ? "bold" : ""}>
                  {item.reportName}
                </span>
              </button>
            </div>
          </div>
          {__renderList(item.id, reportLinks)}
        </li>
      );
    });

    return <ul className="aside-report-list">{html}</ul>;
  };

  // function to render the aside filter
  const _renderFilterAsideReporting = () => {
    return (
      <div className="aside-reports-wrapper" key={11}>
        {caseManagementConfig.isFetching ? (
          <AsideFilterLoader />
        ) : (
          __renderList(0, reportLinks)
        )}
      </div>
    );
  };

  // function to render the section that incudes the filter and the serach bar
  const __renderMainSectionReporting = () => {
    return (
      <div className="filter-content pos-relative">
        <div className="filter-header">
          <h3>
            View Report{" "}
            {selectedReport !== null ? `- ${selectedReport.reportName}` : " "}
          </h3>

          <div className="button-list">
            <button
              className="btn btn-primary btn-border"
              disabled={selectedReport === null}
              onClick={() => __printReport()}
            >
              Print Report
            </button>
          </div>
        </div>
        <div className="content">
          {selectedReport !== null ? (
            <iframe
              ref={iframeEle}
              src={selectedReport.reportLink}
              width="100%"
              style={{ minHeight: "80vh" }}
              frameBorder="0"
            ></iframe>
          ) : (
            <div className="p-3 text-center" style={{ minHeight: "80vh" }}>
              Select Reports to View
            </div>
          )}
        </div>
      </div>
    );
  };

  // function to render the section that incudes the filter and the serach bar
  const __renderMainSection = () => {
    if (mainSection === null) {
      return null;
    }
    const cohortsForm: any = [];
    const mainData: any = [];
    const caseSummary: any = [];

    filterList?.forEach((field: any, index: number) => {
      if (field?.panelID === 2) {
        switch (field.type) {
          case "selectpicker":
            cohortsForm.push(
              <div className="cohorts-filter">
                <DropDown
                  label={field.label}
                  labelExternal={false}
                  key={index}
                  name={field.name}
                  data={field.values}
                  defaultSelected={
                    filterList && filterList[field.controlId]
                      ? filterList[field.controlId].defaultSelectedValue
                      : ""
                  }
                  isMultiSelectPickList={field?.isMultiSelectPickList}
                  onChange={(e: any) => setSelectedCohortId(e.value)}
                />
              </div>
            );
            break;
          case "text":
            cohortsForm.push(
              <TextField
                label={field.label}
                labelExternal={false}
                additionalClass="search-filter"
                name={field.name}
                placeholder={field.placeholder}
                defaultSelected={""}
                onChange={(e: any) => setCohortSearchString(e.value)}
              />
            );

            break;

          case "button":
            cohortsForm.push(
              <Button
                key={index}
                title={field.label}
                className={
                  field.onClick === "callApiAndRefreshPage"
                    ? "btn-cta blue-background"
                    : "btn-cta transparent-background"
                }
                type={
                  field.onClick === "callApiAndRefreshPage" ? "submit" : "reset"
                }
                disabled={cohortsGridData.isFetching || mainGridData.isFetching}
              />
            );
            break;

          default:
            return null;
        }
      }
    });

    const __submitEditCaseRecord = (e: any) => {
      try {
        dispatch(
          editCaseRecordById({
            token: token,
            params: {
              controlId: gridId,
              dspId: selectedDSP,
              id: editCaseData.DgsId,
              oneViewReference: editCaseData.Group_Ref,
            },
            body: e,
          })
        );
      } catch (e) {
        toast.error("Something went wrong");
      }
    };

    mainSection.primarySections.forEach((item: any, index: number) => {
      switch (item.type) {
        case "datagrid":
          mainData.push(
            gridData !== null ? (
              <KendoGrid
                gridData={gridData}
                isFilterable={false}
                filtersList={item.dataGrid.filters}
                isPaginated={!isCaseDetailedMode}
                onExpand={(e: any) => __fetchChildData(e)}
                customAction={(e: any) => __onCustomAction(e)}
                onHiddenCount={(e: any) => {
                  setHiddenCountColumns(e);
                }}
                expandField="details"
                selectedField="SELECTED_FIELD"
                onSelectionChange={(e: any) => {
                  setSelectedCase(e);
                }}
                isSelectable={{
                  mode: "single",
                }}
                isCustomAction={true}
                customeActionLayout={(resData: any) => (
                  <CustomAction
                    data={resData}
                    isDetails={true}
                    isEditButton={true}
                    onClick={(e: any) => __onCustomAction(e)}
                  />
                )}
              />
            ) : null
          );
          break;

        case "tabs":
          caseSummary.push(
            <div>
              <Tabs
                key={index}
                tabs={item.tabs}
                activeTab={item?.tabs[0]?.tabId}
                caseSummary={
                  <CaseSummaryCM
                    onCaseClose={() => {
                      setIsCaseDetailedMode(false);
                      setSelectedCaseReference(null);
                      setSelectedCase(null);
                    }}
                    selectedCaseReference={selectedCaseReference}
                    token={token}
                    dsp={selectedDSP}
                  />
                }
                Outcome={
                  <OutcomesGrid
                    selectedCaseReference={selectedCaseReference}
                    token={token}
                    dsp={selectedDSP}
                    tabs={item.tabs[2]}
                  />
                }
                EngagementTracker={
                  <EngagmentTrackerGrid
                    selectedCaseReference={selectedCaseReference}
                    gridId={gridId}
                    token={token}
                    dsp={selectedDSP}
                    tabs={item.tabs[1]}
                  />
                }
                caseNotes={
                  <CasedetailsGrid
                    selectedCaseReference={selectedCaseReference}
                    token={token}
                    dsp={selectedDSP}
                    tabs={item.tabs[3]}
                  />
                }
                // t={"outcomes"} caseData={caseDetailAlldata} pageSize={10}
                onClickTab={(id: string) => {
                  // props.onClickFetchCaseData(id)
                }}
              />
            </div>
          );
          break;

        default:
          return null;
      }
    });

    return (
      <div>
        {/* TODO: cohorts are migrated to main filter  */}
        {/* <form method="get" onSubmit={(e) => __onSubmitCohorts(e)}>
          <div className="search-filter-container">{cohortsForm}</div>
        </form> */}

        {/* dropdown option for filtering the case  */}
        <div className="filter-content pos-relative">
          <div className="filter-header">
            <h3>
              {t("CASES")}{" "}
              <span className="">{`(${
                totalDataCount ? totalDataCount : 0
              } count)`}</span>
            </h3>
            <div className="button-list">
              {/* 
              TODO: check kendo grid api 
              */}
              {/* <button
                disabled={
                  mainGridData.isFetching ||
                  cohortsGridData.isFetching ||
                  childGridData.isFetching
                }
                className="btn btn-primary btn-border"
                onClick={() => {
                  document.dispatchEvent(exportToExcel);
                }}
              >
                Export
              </button>
              <div className="space"></div> */}
              {/* <button className="btn btn-primary btn-border" onClick={() => {
                document.dispatchEvent(expandColumns);
                setIsHiddenColumns(!isHiddenColumns);
              }}>{isHiddenColumns ? "-" : "+"} {hiddenCountColumns}</button> */}
            </div>
          </div>
          <div className="content">
            {mainGridDataLoading ? (
              <div className="loader">{<GridLoader />}</div>
            ) : (
              mainData
            )}
          </div>

          {editCasePopup && (
            <EditCasePopup
              token={token}
              dspId={selectedDSP}
              data={editCaseData}
              controlId={gridId}
              closeModal={() => {
                setEditCasePopup(false);
                setEditCaseData(null);
              }}
              onSubmit={(e: any) => __submitEditCaseRecord(e)}
            />
          )}

          {showFeedbackpopup ? (
            <FeedbackPopup
              dsp={selectedDSP}
              caseSummaryType={caseSummaryType}
              sectionId={props.sectionId}
              selectedCaseReference={selectedCaseReference}
              title={"Feedback"}
              onClose={() => setShowFeedbackpopup(false)}
              onOpen={() => {
                setShowFeedbackpopup(false);
                //setShowAddFeedbackpopup(true)
              }}
            />
          ) : null}
          {selectedCaseReference !== null ? (
            <div className="overlay"></div>
          ) : null}
        </div>

        {
          <div className="filter-content case-details">
            <div
              className={
                selectedCaseReference !== null
                  ? "filter-header"
                  : "filter-header filter-disabled"
              }
            >
              <h3>
                {selectedCaseReference !== null
                  ? t("CASE_DETAILS_ID") + selectedCaseReference
                  : t("CASE_DETAILS")}

                {hhcalertdata.isSuccess && HHCMessage !== null ? (
                  <button
                    className="btn ms-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="New Notification"
                    onClick={() => {
                      toast.success(HHCMessage);
                      // setTimeout(() => {
                      //   setHHCMessage(null)
                      // }, 1000)
                    }}
                    style={{
                      border: "1px solid #5464B0",
                      borderRadius: 15,
                      width: 25,
                      height: 25,
                      padding: 0,
                      background: "#ffffff",
                    }}
                  >
                    <img
                      src={notification}
                      alt="notification"
                      style={{ width: 14 }}
                    />
                  </button>
                ) : null}
              </h3>
              {isCaseDetailedMode ? (
                <div>
                  {isShowFeedback ? (
                    <button
                      className="btn-feedback btn btn-collapse feedback-button"
                      onClick={() => {
                        console.log("Feedback button clicked");
                        feedbackCsApiCall(token, selectedCaseReference);
                        setShowFeedbackpopup(true);
                      }}
                    >
                      {feedbackList == 0
                        ? "Add Feedback"
                        : "Edit/View Feedback"}
                    </button>
                  ) : null}

                  <button
                    className="btn btn-transparent"
                    onClick={() => {
                      setData(GROUP_REF_SELETECTED, "");
                      setIsCaseDetailedMode(false);
                      setSelectedCaseReference(null);
                      setSelectedCase(null);
                      setHHCMessage(null);
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                </div>
              ) : null}
            </div>

            {selectedCaseReference !== null ? (
              <div className="content">{caseSummary}</div>
            ) : null}
          </div>
        }
      </div>
    );
  };

  return (
    // dashboard component
    <AuthWrapper>
      <div className="dashboard">
        {/* header component  */}
        <TopHeader
          username={isUsername}
          isReporting={isReporting}
          // createCasePopupVal={state.createCasePopup}
          // generateData={setState({ ...state, generateData: false })}
          // protocol={setState({ ...state, protocol: false })}
          // generateVal={state.generateData}
          onCreateCaseClick={() => {
            console.log("Create Case Clicked");
            __callCreateCaseApi();
            // setCreateCasePopup(true);
          }}
          onChange={(e: any) => {
            setSelectedDSP(e.value);
            __fetchSelectedDSP(e.value);
          }}
        />

        {createCaseshowModel && (
          <CreateCasePopup
            token={props.token}
            dspId={props.dsp}
            data={createCaseStateData}
            isFetching={false}
            onSubmit={(type: string, res: any) => {
              setCreateCaseshowModel(false);
              // if (res === null) {
              //   __submitIgPluginAuth("closeForm", res);
              // } else {
              //   __submitIgPluginAuth(type, res);
              // }
            }}
          />
        )}

        {/* two TwoColumnLayout for dashboard */}

        {isReporting ? (
          <TwoColumnLayout
            asideTitle={t("Reports")}
            aside={_renderFilterAsideReporting()}
            main={__renderMainSectionReporting()}
          />
        ) : (
          <TwoColumnLayout
            asideTitle={selectedCaseReference === null ? t("Filter") : ""}
            aside={_renderFilterAside()}
            main={__renderMainSection()}
          />
        )}

        {isCommunityAssets && (
          <CommunityAssets
            dsp={selectedDSP}
            group_reference={selectedCase?.Group_Ref}
            postcode={selectedCase?.Postcode}
            token={token}
            onClose={() => {
              setIsCommunityAssets(!isCommunityAssets);
            }}
          />
        )}

        {/* TODO: section for the map  */}
        {!isCommunityAssets && isCommunityAssetsEnabled && (
          <div className="community-assets-btn">
            <button
              onClick={() => {
                setIsCommunityAssets(!isCommunityAssets);
              }}
              className="btn btn-primary map"
              aria-current="page"
            >
              <img src={map_white} alt="map" />
            </button>
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default Dashboard;
