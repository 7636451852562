import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { closeIcon } from "../../../assets/icons";
import { Button } from "../../../components";
import { addOutcomesData } from "../store/actions";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import { t } from "i18next";
import ChildPicklistIControlidsPicker from "./ChildPicklistIControlidsPicker";
import EngagmentSelectPicker from "./EngagmentSelectPicker";

// Functional component for Information Governance
const AddEngagmentPopup = (props: any) => {
    
    // State for storing the "involve case" data
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [childPicklistControlIds, setChildPicklistControlIds] = useState<any>(null);

    // State for storing form data

    const formObj: any = {};
    props?.data?.popupForm?.controls?.forEach((element: any) => {
        formObj[element.name] = element?.value === undefined ? "" : element.name === "ContactDate" || element.name === "CommitmentWeWillStartDate" || element.name === "CommitmentYouWillStartDate" ? moment(element.value, 'MM/DD/YYYY h:mm:ss A').format('DD/MM/YYYY') : element.value;
    });
    const [formData, setFormData] = useState<any>(formObj);


    useEffect(() => {
        setIsFetching(false);
    }, [props.errorFlag]);

    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {

        if (Object.values(formData).some(a => a !== '')) {
            setIsFetching(true);
            props.onSubmit("formSubmit", formData);
        } else {
            toast.error("Please select values");
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: name === "ContactDate" || name === "CommitmentWeWillStartDate" || name === "CommitmentYouWillStartDate" ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : value });
    };

    useEffect(() => {
        if (props.isFetching !== isFetching) {
            setIsFetching(props.isFetching);
        }
    }, [props.isFetching]);

    useEffect(() => {
        if (!Object.values(formData).some(a => a !== '')) {
            setIsEdit(true);
        }
    }, []);

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        let itemsToRender = [];
        switch (items.type) {
            case "selectparentpicker":
                itemsToRender.push(
                    <ChildPicklistIControlidsPicker
                        formData={formData}
                        hidden={!items.visible}
                        token={props.token}
                        dspId={props.dspId}
                        items={items}
                        handleChange={handleChange}
                    />
                );

                if (childPicklistControlIds === null) {
                    setChildPicklistControlIds(items.childPickListControlIds.split(","));
                }
                break;
            case "text":
                itemsToRender.push(
                    <div hidden={!items.visible} className="column">
                        <h6 className="involve-case-title">{items.label}</h6>
                        <input
                            disabled={!items.isEditable}
                            name={items.name}
                            type={items.type}
                            value={formData[items.name]}
                            onChange={handleChange}
                        ></input>
                    </div>
                );
                break;
            case "selectpicker":
                itemsToRender.push(
                    <EngagmentSelectPicker
                        isChild={childPicklistControlIds}
                        formData={formData}
                        hidden={!items.visible}
                        token={props.token}
                        dspId={props.dspId}
                        items={items}
                        handleChange={handleChange}
                    />
                );
                break;
            case "datepicker":

                itemsToRender.push(
                    <div hidden={!items.visible} className="column" key={items.controlId}>
                        <h6 className="involve-case-title">{items.label}</h6>
                        <input
                            disabled={!items.isEditable}
                            type="date"
                            value={moment(formData[items.name], 'DD/MM/YYYY').format('YYYY-MM-DD')}
                            name={items.name}
                            key={items.name}
                            onChange={handleChange}
                        />
                    </div>
                );
                break;
            default:
                return null;
        }

        return itemsToRender;
    };

    return (
        props?.data?.popupForm?.allowAdd == true || !isEdit ?
            <div className="addoutcome-popup">
                <div
                    className="modal fade show"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {isEdit ?
                                        t("ADDENGAGEMENTPOPUPHEAD")
                                        :
                                        t("EDITENGAGEMENTPOPUPHEAD")
                                    } {props.title}
                                </h5>
                                <button className="btn btn-transparent close" onClick={() => {
                                    props.onSubmit("", null);
                                }} >
                                    <img src={closeIcon} alt="close" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {props?.data?.popupForm === undefined && <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>}
                                <form
                                    className="information-governance-container"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        callApiAndRedirect(e);
                                    }}
                                >
                                    <div className="content-container">
                                        <div className="row">
                                            {props?.data?.popupForm?.controls?.map((items: any, index: any) => {
                                                return __renderLayout(items, index);
                                            })}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    key={""}
                                    title={"Cancel"}
                                    className="button cancel-button"
                                    type="button"
                                    onClick={() => props.onSubmit("", null)}

                                />
                                <Button
                                    key={""}
                                    title={isFetching ? t("REQUESTING") : props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.label || t("SAVE")}
                                    name={props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.name}
                                    className={
                                        "button save-button " +
                                        (isFetching ? "requesting btn-disable" : "")
                                    }
                                    type="submit"
                                    onClick={callApiAndRedirect}
                                    disabled={props.isEditable || isFetching}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    );
};

export default AddEngagmentPopup;
