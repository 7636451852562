const createCase = [{name:"Ricky henderson", dob:"27/11/1979", address: "51 Dene Road Didsbury Manchester M202TG", postcode: "M202TG"},
{name:"Riley Otieno ", dob:"12/01/1991", address: " Flat 1 31 Clyde Road Manchester M202Jj        M202JJ", postcode: "M202JJ"},
{name:"Muzainah Kaza ", dob:"30/01/2017", address: " 13 Bromborough Avenue Manchester M201BP", postcode: "M201BP"},
{name:"Zeyana hamid rutaisire ", dob:"13/08/1996", address: " 87 Meltham Avenue Manchester M201FE", postcode: "M201FE"},
{name:"Brogan yellop ", dob:"31/01/2003", address: " Flat 1 1 Old Broadway Manchester M203DH", postcode: "M203DH"},
{name:"Jayce alan david islam ", dob:"27/02/1998", address: " Flat 1 20-24 Orchard Street Manchester        M202LP", postcode: "M202LP"},
{name:"Yousif brown ", dob:"13/06/1993", address: " Flat 2 9 Chatham Grove Manchester M201HS", postcode: "M201HS"},
{name:"Reggie davies ", dob:"06/06/2006", address: " Flat 3 8-12 Orchard Street Manchester        M202LP", postcode: "M202LP"},
{name:"Aahir izfaar butt ", dob:"09/06/2004", address: " Flat 8 22 Northen Grove Manchester M202WL", postcode: "M202WL"},
{name:"Kwabena rutaisire ", dob:"18/09/2002", address: "Flat 2 8 Cresswell Grove Manchester M202NH", postcode: "M202NH"},
{name:"William ian haddon-robertson ", dob:"29/04/2006", address: "64 Mouldsworth Avenue Manchester M201AW", postcode: "M201AW"},]

export default createCase;