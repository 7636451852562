import React, { useEffect, useRef, useState } from "react";
import { selectFieldProps } from "../../interfaces";
import { title } from "process";

const DropDown: React.FC<selectFieldProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Ensure selectedValue is always an array to support multiple selections
  const [selectedValue, setSelectedValue] = useState<any[]>([]);
  const [data, setData] = useState<any[]>(props.data || []);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setData(props.data || []);
  }, [props.data]);

  useEffect(() => {
    if (props.defaultSelected !== null && props.defaultSelected !== undefined && props.defaultSelected !== "") {
      
      const defaultSelectedValues = Array.isArray(props.defaultSelected) ? props.defaultSelected : [props.defaultSelected];
      const selectedItems = props.data.filter((item: any) => defaultSelectedValues.includes(item.value));

      setSelectedValue([...selectedItems]);
    }else {
      setSelectedValue([]);
    }
  }, [props.defaultSelected]);

  const __openDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selectedValue.length > 0 && selectedValue.every((val) => val.value !== "")) {
      // If multiselect, pass the entire array, otherwise pass the single selected item

      if (!props.isMultiSelectPickList) {
        props.onChange(selectedValue[0]);
      } else {

        const indexOf = selectedValue.find((val) => val.value === "all")
        
        
        if (indexOf !== undefined && indexOf.value === "all") {
          props.onChange(data);
        }else {
          props.onChange(selectedValue);
        }
      }
    }
  }, [selectedValue]);



  const __selectOption = (e: any, item: any) => {
    e.preventDefault();

    if (props.isMultiSelectPickList) {
      // Check if item is already selected
      const index = selectedValue.findIndex((selectedItem) => selectedItem.value === item.value);
      if (index > -1) {
        // Remove item if already selected
        setSelectedValue(selectedValue.filter((_, i) => i !== index));
      } else {
        // Add item to selected values
        
        if (item.value === "all") {
          setSelectedValue([item]);
          setIsOpen(false);
        }else {
          setSelectedValue([...selectedValue, item]);
        }
      }
    } else {
      if (item.id === selectedValue[0]?.id) {
        setSelectedValue([]);
      } else {
        setSelectedValue([item]);
      }

      setIsOpen(false);
    }
  };


  useEffect(() => {
    if (isOpen) {
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      const buttonRect = buttonRef.current?.getBoundingClientRect();
      if (dropdownRect && buttonRect) {
        const isOverflowingBottom = dropdownRect.bottom > window.innerHeight;
        const isOverflowingRight = dropdownRect.right > window.innerWidth;

        setDropdownStyle({
          top: isOverflowingBottom ? '100%' : '100%',
          bottom: isOverflowingBottom ? '100%' : 'auto',
          left: isOverflowingRight ? 'auto' : '0',
          right: isOverflowingRight ? '0' : 'auto',
        });
      }
    }
  }, [isOpen]);


  const __renderClearButton = () => {
    if (selectedValue.length === 0) {
      return null
    }
    return <button onClick={() => {
      setSelectedValue([]);
    }}>X</button>

  }

  return (
    <>
      <div className="filter-fields">
        {props.labelExternal && (
          <>
            <h3 className="title">{props.label}</h3>
          </>
        )}
        <div className={isOpen ? "select-container open" : "select-container"} data-ismultiselect={props.isMultiSelectPickList}>
          <div className="select-button-wrapper">
            
            <button ref={buttonRef} onClick={__openDropdown} type="button">
              {/* Render selected item titles or default label if none */}
              {selectedValue.length
                ? selectedValue.map((val) => val.title).join(", ")
                : "Select an option"}
            </button>

            {/* {
              __renderClearButton()
            } */}

          </div>

          {isOpen && (
            <div className="ul-wrapper">
            <ul ref={dropdownRef} className="dropdown-list" style={dropdownStyle}>
              {
                props.isMultiSelectPickList ? <li
                  key={"all"}
                  className={
                    selectedValue.find((val) => val.value === "all") ? "item active" : "item"
                  }
                >
                  <button onClick={(e) => __selectOption(e, { id: "all", title: "All", value: "all" })} type="button">All</button>
                </li> : null
              }


              {data.length ? (
                data.map((item, index) => (
                  <li
                    key={index}
                    className={
                      selectedValue.find((val) => val.value === item.value || val.value === "all") ? "item active" : "item"
                    }
                  >
                    <button onClick={(e) => __selectOption(e, item)} type="button">
                      {props.isMultiSelectPickList ? <span className="checkbox" /> : null}
                      <span className={props.isMultiSelectPickList ? "px-2" : ""}>{item.title}</span>
                    </button>
                  </li>
                ))
              ) : (
                <div>Fetching...</div>
              )}
            </ul>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          onClick={__openDropdown}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
          }}
        ></div>
      )}
    </>
  );
};

export default DropDown;